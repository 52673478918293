// Space vertical
.wrap-space-vertical {
  > .space-3-space-item {
    width: 100%;
  }
}

.btn-ghost-clip-path {
  min-height: 32px;
  width: auto;
  clip-path: polygon(
    10px 0,
    100% 0,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    0 100%,
    0 10px
  );
  background: #42bab0;

  &-body {
    clip-path: polygon(
      11px 0,
      100% 0,
      100% calc(100% - 11px),
      calc(100% - 11px) 100%,
      0 100%,
      0 11px
    );

    background: #0f191b !important;
    font-weight: 700 !important;
    font-family: 'Space Mono', monospace;
  }
}

.social-community-icons {
  transition: all 0.2s ease-in !important;

  &:hover {
    color: #67fff2 !important;
  }
}

.empty-sp3 {
  width: 100%;
  margin-top: 48px;
  text-align: center;

  img {
    margin-bottom: 40px;
  }
}
