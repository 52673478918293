.sp3-skeleton {
  width: 100% !important;
  height: 100% !important;

  .space-3-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .space-3-skeleton-image {
    border-radius: 8px !important;
  }
}
