@import '@/theme/vars';

.btn-circle-primary {
  background: transparent !important;
  border: 1px solid $SECONDARY !important;
  color: $PRIMARY !important;
  width: 48px !important;
  height: 48px !important;
  transition-property: all;

  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  &:hover {
    border-color: transparent !important;
    box-shadow: 0 0 6px 0 $PRIMARY !important;
  }
}

.btn-icon-primary {
  background: transparent !important;
  color: $PRIMARY !important;
}

.wallet-button {
  border: none;
  &:hover {
    border: 0.5px solid #0fdbd1;
  }
}
