@import '@/theme/vars';

/* Style for 3D Swiper */
.swiper-3d-container {
  position: relative;
  margin: 0 auto;
  background: transparent;
  width: 100%;
  animation-name: fadeInSwiper;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  overflow: hidden;

  .swiper-slide-active {
    .card-mission {
      outline: 0.5px solid $PRIMARY;
    }
    .reward-center-card {
      outline: 0.5px solid $WARNING;
    }
  }

  .swiper {
    background: transparent;

    &-wrapper {
      transform: translateX(calc(50% - 142px)) !important;
    }
  }

  .swiper-slide-container {
    width: 280px !important;
    margin: 1px 2px;
    transform: scale(0.75);
    overflow: unset;
  }

  .btn-navigate-swiper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  .btn-navigate-swiper.space-3-swiper-next-element {
    right: 50% !important;
    transform: translateX(calc(105px + 130px)) translateY(-50%) scale(0.75) !important;
  }

  .btn-navigate-swiper.space-3-swiper-prev-element {
    left: 50% !important;
    transform: translateX(calc(-105px - 130px)) translateY(-50%) scale(0.75) !important;
  }

  .swiper-slide:not(
      .swiper-slide-active,
      .swiper-slide-prev,
      .swiper-slide-next
    ) {
    opacity: 0 !important;
  }

  .swiper-slide-visible:not(
      .swiper-slide-active,
      .swiper-slide-prev,
      .swiper-slide-next
    ) {
    opacity: 0 !important;
  }

  .swiper-slide-prev,
  .swiper-slide-next {
    opacity: 1 !important;
  }

  @media screen and (max-width: 1200px) {
    .swiper {
      &-wrapper {
        transform: translateX(calc(50% - 142px)) !important;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .swiper {
      &-wrapper {
        transform: translateX(calc(50% - 142px)) !important;
      }
    }

    .swiper-slide:not(.swiper-slide-active) {
      opacity: 0 !important;
    }

    .btn-navigate-swiper.space-3-swiper-next-element {
      right: 50% !important;
      transform: translateX(105px + 50px) translateY(-50%) scale(0.75) !important;
    }

    .btn-navigate-swiper.space-3-swiper-prev-element {
      left: 50% !important;
      transform: translateX(-105px - 50px) translateY(-50%) scale(0.75) !important;
    }
  }
}

.swiper-3d-container--reward-center {
  .swiper-slide-container {
    height: 460px !important;
  }
}

.swiper-3d-container--featured {
  .swiper-slide-container {
    height: 460px !important;
  }

  .swiper-slide:not(
      .swiper-slide-active,
      .swiper-slide-prev,
      .swiper-slide-next
    ) {
    opacity: 0 !important;
  }

  .swiper-slide-visible:not(
      .swiper-slide-active,
      .swiper-slide-prev,
      .swiper-slide-next
    ) {
    opacity: 0 !important;
  }

  .swiper-slide {
    &.swiper-slide-prev,
    &.swiper-slide-next {
      opacity: 0.4 !important;
    }
  }

  .btn-circle-primary {
    background: $BG_SECONDARY !important;
  }

  .btn-navigate-swiper.space-3-swiper-next-element {
    right: 50% !important;
    transform: translateX(calc(105px + 105px)) translateY(-50%) scale(0.75) !important;
  }

  .btn-navigate-swiper.space-3-swiper-prev-element {
    left: 50% !important;
    transform: translateX(-105px - 105px) translateY(-50%) scale(0.75) !important;
  }

  @media screen and (max-width: 1199px) {
    .swiper {
      &-wrapper {
        transform: translateX(calc(50% - 140px)) !important;
      }
    }

    .btn-navigate-swiper.space-3-swiper-next-element {
      right: 50% !important;
      transform: translateX(calc(105px + 70px)) translateY(-50%) scale(0.75) !important;
    }

    .btn-navigate-swiper.space-3-swiper-prev-element {
      left: 50% !important;
      transform: translateX(-105px - 70px) translateY(-50%) scale(0.75) !important;
    }
  }

  @media screen and (max-width: 991px) {
    .swiper {
      &-wrapper {
        transform: translateX(calc(50% - 140px)) !important;
      }
    }

    .btn-navigate-swiper.space-3-swiper-next-element {
      right: 50% !important;
      transform: translateX(105px + 125px) translateY(-50%) scale(0.75) !important;
    }

    .btn-navigate-swiper.space-3-swiper-prev-element {
      left: 50% !important;
      transform: translateX(-105px - 125px) translateY(-50%) scale(0.75) !important;
    }
  }

  //@media screen and (max-width: 767px) {
  //  //.swiper {
  //  //  &-wrapper {
  //  //    transform: unset !important;
  //  //  }
  //  //}
  //
  //  .swiper-slide:not(
  //      .swiper-slide-active,
  //      .swiper-slide-prev,
  //      .swiper-slide-next
  //    ) {
  //    opacity: 1 !important;
  //  }
  //
  //  .swiper-slide-visible:not(
  //      .swiper-slide-active,
  //      .swiper-slide-prev,
  //      .swiper-slide-next
  //    ) {
  //    opacity: 1 !important;
  //  }
  //
  //  .swiper-slide {
  //    &.swiper-slide-prev,
  //    &.swiper-slide-next {
  //      opacity: 1 !important;
  //    }
  //  }
  //
  //  .btn-navigate-swiper.space-3-swiper-next-element {
  //    display: none !important;
  //    right: 50% !important;
  //    transform: translateX(105px + 50px) translateY(-50%) scale(0.75) !important;
  //  }
  //
  //  .btn-navigate-swiper.space-3-swiper-prev-element {
  //    display: none !important;
  //    left: 50% !important;
  //    transform: translateX(-105px - 50px) translateY(-50%) scale(0.75) !important;
  //  }
  //}

  @media screen and (max-width: 474px) {
    //.swiper {
    //  &-wrapper {
    //    transform: unset !important;
    //  }
    //}

    .swiper-slide:not(
        .swiper-slide-active,
        .swiper-slide-prev,
        .swiper-slide-next
      ) {
      opacity: 1 !important;
    }

    .swiper-slide-visible:not(
        .swiper-slide-active,
        .swiper-slide-prev,
        .swiper-slide-next
      ) {
      opacity: 1 !important;
    }

    .swiper-slide {
      &.swiper-slide-prev,
      &.swiper-slide-next {
        opacity: 1 !important;
      }
    }

    .btn-navigate-swiper.space-3-swiper-next-element {
      right: 50% !important;
      transform: translateX(105px + 50px) translateY(-50%) scale(0.75) !important;
    }

    .btn-navigate-swiper.space-3-swiper-prev-element {
      left: 50% !important;
      transform: translateX(-105px - 50px) translateY(-50%) scale(0.75) !important;
    }
  }
}
/* End of Style for 3D Swiper */

/* Styles for DEFAULT Swiper */
.sp3-swiper-container {
  position: relative;
  width: 100%;
  background: transparent;

  &.sp3-swiper-pagination-outer {
    margin-bottom: 28px;

    [class*='-swiper-pagination'] {
      bottom: -20px;
    }
  }

  &.sp3-swiper-pagination-inner {
    margin-bottom: 0 !important;
  }

  .swiper {
    background: transparent;
  }

  .btn-navigate-swiper--sm {
    min-width: unset !important;
    width: 24px !important;
    height: 24px !important;
  }

  .btn-navigate-swiper--md {
    min-width: unset !important;
    width: 32px !important;
    height: 32px !important;
  }

  .btn-navigate-swiper--mg {
    min-width: unset !important;
    width: 44px !important;
    height: 44px !important;
  }

  .btn-navigate-swiper--lg {
    min-width: unset !important;
    width: 48px !important;
    height: 48px !important;
  }

  .btn-navigate-swiper--sm,
  .btn-navigate-swiper--md,
  .btn-navigate-swiper--lg,
  .btn-navigate-swiper--mg {
    background-color: #060708 !important;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    z-index: 1;
  }

  .swiper-button-disabled {
    display: none !important;
  }

  &:has(.show-disabled-nav-btn) {
    .swiper-button-disabled {
      display: flex !important;
      border: $SECONDARY 1px solid !important;
      color: $SECONDARY !important;
      &:hover {
        box-shadow: unset !important;
      }
    }
  }

  [class*='-swiper-pagination'] {
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: 2;

    .swiper-pagination-bullet {
      background: $TEXT_COLOR_PRIMARY;

      &-active {
        background: $PRIMARY !important;
      }
    }
  }
}
/* End of Styles for DEFAULT Swiper */

/* Styles for GALLERY Swiper */
.swiper-thumbs-gallery-container {
  .swiper-slide {
    cursor: pointer;
    border: 1px solid transparent;
    &-thumb-active {
      border-radius: 8px;
      border: 1px solid $SUCCESS;
    }
  }

  .swiper-thumbs-gallery__control {
    .swiper-thumbs-gallery__control--thumbs {
      cursor: pointer;

      .media-container {
        pointer-events: none !important;
      }
    }
  }
}

.swiper-thumbs-gallery {
  &--btn-prev,
  &--btn-next {
    border: none;
    box-shadow: none;
    background: transparent !important;

    position: absolute;
    top: 50%;
    z-index: 1;
    span {
      transform: scale(1.5);
    }
  }
  &--btn-prev {
    transform: rotate(180deg) translateY(50%);
    left: -38px;
  }
  &--btn-next {
    transform: translateY(-50%);
    right: -38px;
  }
}
/* End styles for GALLERY Swiper */

@keyframes fadeInSwiper {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ytp-large-play-button {
  width: 40px !important;
}

/* Styles for SLIDES Swiper */

.swiper-slides-container {
  width: 100%;
  position: relative;

  .swiper {
    padding: 10px;
  }

  .swiper-slides {
    &--btn-prev,
    &--btn-next {
      box-shadow: none;
      border: none !important;
      background: #1b1b21 !important;

      position: absolute;
      top: 50%;
      z-index: 1;
      span {
        transform: scale(1);
      }
    }
    &--btn-prev {
      transform: rotate(180deg) translateY(50%);
      left: -14px;
    }
    &--btn-next {
      transform: translateY(-50%);
      right: -14px;
    }
  }

  .swiper-slides-swiper-pagination {
    .swiper-pagination-bullet {
      background-color: $DIVIDER_COLOR;
      width: 12px;
      height: 12px;
    }
    .swiper-pagination-bullet-active {
      background-color: $PRIMARY;
    }
  }

  .swiper-slides--empty-action {
    display: none;
  }
}
/* End of Style for SLIDES Swiper */
