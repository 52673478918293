@mixin sp3-tag {
  display: flex;
  align-items: center;
  height: 18px;
  padding: 4px 16px;

  span {
    font-size: 10px;
    font-weight: 300;
    text-transform: capitalize;
    letter-spacing: 0.2px;
  }
}

.sp3-tag {
  &--clip-bottom {
    @include sp3-tag;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 10px 100%, 0 calc(100% - 10px));
  }

  &--clip-top {
    @include sp3-tag;
    clip-path: polygon(10px 0, 100% 0, 100% 100%, 0 100%, 0 10px);
  }

  &--default-bottom {
    @include sp3-tag;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 4px;
  }

  &--default-top {
    @include sp3-tag;
    border-top-left-radius: 12px;
    border-top-right-radius: 4px;
  }
}

.amount-tag {
  padding: 4px 6px;
  font-size: 12px;
  color: #8feeea;
  letter-spacing: 0.2px;
  line-height: 0.7;
  border-radius: 0px 7.5px 0px 2px;
  background: linear-gradient(180deg, #0a9790 0%, #042b29 100%);
  backdrop-filter: blur(10px);
}

.amount-tag-rounded {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  min-width: 28px;
  height: 20px;
  border-radius: 9999px;
  background: linear-gradient(180deg, #0fdbd1 0%, #087570 100%);

  span {
    color: #090a0b;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 0;
  }
}
