@import '@/theme/vars';

.sp3-swiper-container {
  position: relative;
  .swiper-pagination-bullets-dynamic {
    position: absolute;
    bottom: -20px;

    .swiper-pagination-bullet-active {
      background: $PRIMARY !important;
    }

    .swiper-pagination-bullet-active-main {
      background: #29333d;
      opacity: 1;
    }
  }
}
