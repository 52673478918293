$PRIMARY: #0fdbd1;
$SECONDARY: #4d5a66;

$SUCCESS: #0fdbd1;
$WARNING: #ff7300;
$DANGER: #db4646;

$SUCCESS_DARK: #0baca4;
$WARNING_LIGHT: #d7a647;

$TEXT_COLOR_PRIMARY: #ffffff;
$TEXT_COLOR_SECONDARY: #ebebeb;

$TEXT_COLOR_INFO: #618df6;
$TEXT_COLOR_GREEN: #1eaf6a;
$TEXT_COLOR_DARK: #1e1e1e;
$TEXT_COLOR_GRAY: #828282;
$TEXT_COLOR_LIGHT_ORANGE: #ff9d4c;

$BG_PRIMARY: #060708;
$BG_SECONDARY: #0d0f11;
$BG_GREEN: #1eaf6a;
$BG_CONTENT: #121416;
$BG_CONTENT_LIGHT: #1c2023;

$ACTIVITY_CARD_BG: #0d0f11;

$CARD_BG_GRAY: #151b21;

$TRANSITION_MAIN: all 0.3s ease-in-out;

$MAX_WIDTH_MOBILE: 991px;

$PADDING_MOBILE: 16px;

$EMAIL_INVALID_DANGER: #ff2424;

$NOT_VALUE_BUTTON: #076661;

$DIVIDER_COLOR: #29333d;

$DISABLE_LIGHT: #67798b;

$BORDER_COLOR_DARK: #1d232b;

$BG_MASK: #000000cc;

$BG_MODAL_CONTENT: #070d0e;
